<template>
  <StatCard
    theme="dark"
    :icon="require(`@/assets/images/icon-invoice.svg`)"
    :loading="overdueInvoicesLoading"
    :error="overdueInvoicesErrorMessage"
    @retry="fetchOverdueInvoices"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{ $t('dashboard.statistics.billing.overdue-invoices.title') }}
        </p>
        <h3 class="m-0">
          {{ overdueInvoices.totalAmount }} {{ overdueInvoices.currency }}
        </h3>
      </div>
    </template>
    <template #foot>
      <div class="d-flex flex-column align-items-center">
        <h5 class="m-0">
          {{ $t('dashboard.statistics.billing.overdue-invoices.foot-title') }}:
          {{ overdueInvoices.invoicesCount }}
        </h5>
        <p class="m-0">
          {{
            $t('dashboard.statistics.billing.overdue-invoices.foot-subtitle')
          }}
        </p>
      </div>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'overdueInvoices',
      'overdueInvoicesLoading',
      'overdueInvoicesErrorMessage'
    ])
  },
  methods: {
    ...mapActions(['fetchOverdueInvoices'])
  },
  mounted() {
    this.fetchOverdueInvoices()
  }
}
</script>
<style lang="scss" scoped></style>
