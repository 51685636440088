<template>
  <div class="chart-card">
    <div class="head">
      <h5 class="title">{{ title }}</h5>
    </div>
    <div class="body">
      <half-circle-spinner
        :animation-duration="1500"
        :size="50"
        color="#008ecf"
        class="spinner"
        v-if="loading"
      />
      <div class="error-message" v-else-if="!loading && !!error">
        <p>{{ error }}</p>
        <b-button variant="primary" size="sm" @click="$emit('retry')">
          retry
        </b-button>
      </div>
      <slot v-else />
    </div>
  </div>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  components: {
    HalfCircleSpinner
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['retry']
}
</script>

<style lang="scss" scoped>
.chart-card {
  .head {
    background-color: var(--primary);
    color: white;
    padding: 0.75rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    .title {
      text-align: center;
      margin: 0;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 1.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    .error-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.25rem;
      p {
        margin: 0;
        color: red;
      }
    }
  }
}
</style>
