<template>
  <StatCard
    :icon="require(`@/assets/images/icon-usage.svg`)"
    :loading="usageLoading"
    :error="usageErrorMessage"
    @retry="fetchUsage"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{
            $t('dashboard.statistics.billing.usage.title', {
              days: usage.days || 0
            })
          }}
        </p>
        <h3 class="m-0">{{ usage.amount }} {{ usage.currency }}</h3>
      </div>
    </template>
    <template #foot>
      <p class="m-0 text-center">
        {{
          $t('dashboard.statistics.billing.usage.foot-title', {
            days: usage.days || 0
          })
        }}
      </p>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters(['usage', 'usageLoading', 'usageErrorMessage'])
  },
  methods: {
    ...mapActions(['fetchUsage'])
  },
  mounted() {
    this.fetchUsage()
  }
}
</script>
<style lang="scss" scoped></style>
