<template>
  <StatCard
    :icon="require(`@/assets/images/icon-waiting_time.svg`)"
    :loading="liveCallsCountLoading"
    :error="liveCallsCountErrorMessage"
    @retry="fetchLiveCallsCount"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{
            $t('dashboard.statistics.service-level-details.live-calls.title')
          }}
        </p>
        <h3 class="m-0">
          {{ liveCallsCount }}
        </h3>
      </div>
    </template>
    <template #foot>
      <h5 class="my-3 text-center text-primary">
        {{
          $t('dashboard.statistics.service-level-details.live-calls.foot-title')
        }}
      </h5>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'liveCallsCount',
      'liveCallsCountLoading',
      'liveCallsCountErrorMessage'
    ])
  },
  methods: {
    ...mapActions(['fetchLiveCallsCount'])
  },
  mounted() {
    this.fetchLiveCallsCount()
  }
}
</script>
<style lang="scss" scoped></style>
