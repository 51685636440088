<template>
  <ChartCard
    :title="$t('dashboard.statistics.call-distribution.title')"
    :error="callDistributionErrorMessage"
    @retry="fetchCallDistribution"
    :loading="callDistributionLoading"
  >
    <LineChart
      :config="callDistribution.config"
      :labels="callDistribution.labels"
      :datasets="callDistribution.datasets"
    />
  </ChartCard>
</template>
<script>
import ChartCard from '../../elements/ChartCard.vue'
import LineChart from '../../elements/charts/LineChart.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'call-distribution',
  components: {
    ChartCard,
    LineChart
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'callDistribution',
      'callDistributionLoading',
      'callDistributionErrorMessage'
    ])
  },
  methods: {
    ...mapActions(['fetchCallDistribution'])
  },
  mounted() {
    this.fetchCallDistribution()
  }
}
</script>

<style lang="scss" scoped></style>
