<template>
  <StatCard
    :icon="require(`@/assets/images/icon-waiting_time.svg`)"
    :loading="extensionsCountLoading"
    :error="extensionsCountErrorMessage"
    @retry="fetchExtensionsCount"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{ $t('dashboard.statistics.extensions.extensions-count') }}
        </p>
        <h3 class="m-0">
          {{
            extensionsCount +
              ' ' +
              $t('dashboard.statistics.extensions.extension')
          }}
        </h3>
      </div>
    </template>
    <template #foot>
      <p class="m-0 text-center">
        {{ $t('dashboard.statistics.extensions.total-extensions') }}
      </p>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'extensionsCount',
      'extensionsCountLoading',
      'extensionsCountErrorMessage'
    ])
  },
  methods: {
    ...mapActions(['fetchExtensionsCount'])
  },
  mounted() {
    this.fetchExtensionsCount()
  }
}
</script>
<style lang="scss" scoped></style>
