<template>
  <StatCard
    :icon="require(`@/assets/images/icon-call_duration.svg`)"
    :loading="averageCallDurationLoading"
    :error="averageCallDurationErrorMessage"
    @retry="fetchAverageCallDuration"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{ $t('dashboard.statistics.service-level-details.monthly.title') }}
        </p>
        <h3 class="m-0">
          {{ averageDuration }}
        </h3>
      </div>
    </template>
    <template #foot>
      <h5 class="m-0 text-center text-primary">
        {{
          $t('dashboard.statistics.service-level-details.monthly.foot-title')
        }}
      </h5>
      <p class="m-0 text-center text-muted">
        {{ $t('dashboard.statistics.service-level-details.monthly.based-on') }}
        {{ averageCallDuration ? averageCallDuration.calls : 0 }}
        {{ $t('dashboard.statistics.service-level-details.monthly.calls') }}
      </p>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'averageCallDuration',
      'averageCallDurationLoading',
      'averageCallDurationErrorMessage'
    ]),
    averageDuration() {
      if (!this.averageCallDuration) return ''
      const { hours, minutes, seconds } = this.averageCallDuration.average
      return this.$t('shared.time', { hours, minutes, seconds })
    }
  },
  methods: {
    ...mapActions(['fetchAverageCallDuration'])
  },
  mounted() {
    this.fetchAverageCallDuration()
  }
}
</script>
<style lang="scss" scoped></style>
