<template>
  <div class="line-chart">
    <vuestic-chart :data="chartData" type="line" class="chart"></vuestic-chart>
  </div>
</template>

<script>
import utils from '../../utils/utils'
import VuesticChart from '../../vuestic-theme/vuestic-components/vuestic-chart/VuesticChart.vue'

export default {
  props: {
    datasets: Array,
    config: Object,
    labels: Array
  },
  computed: {
    chartData() {
      let palette = this.$store.getters.palette
      const defaultBackgroundColor = utils.hex2rgb(palette.info, 0.5).css

      return {
        labels: this.labels,
        datasets: this.datasets.map(dataset => ({
          backgroundColor:
            this.config[dataset.key].backgroundColor || defaultBackgroundColor,
          borderColor: palette.transparent,
          ...dataset
        }))
      }
    }
  },
  components: { VuesticChart }
}
</script>

<style lang="scss" scoped>
.line-chart {
  width: 100%;
  .chart {
    width: 100%;
    height: 240px;
  }
}
</style>
