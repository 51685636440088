<template>
  <ChartCard
    :title="$t('dashboard.statistics.answered-calls-per-day.title')"
    :error="totalAnsweredCallsErrorMessage"
    @retry="fetchTotalAnsweredCalls"
    :loading="totalAnsweredCallsLoading"
  >
    <PieChart
      :config="totalAnsweredCalls.config"
      :labels="totalAnsweredCalls.labels"
      :datasets="totalAnsweredCalls.datasets"
    />
  </ChartCard>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ChartCard from '../../elements/ChartCard.vue'
import PieChart from '../../elements/charts/PieChart.vue'

export default {
  name: 'total-answered-calls',
  components: {
    ChartCard,
    PieChart
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'totalAnsweredCalls',
      'totalAnsweredCallsLoading',
      'totalAnsweredCallsErrorMessage'
    ])
  },
  methods: {
    ...mapActions(['fetchTotalAnsweredCalls'])
  },
  mounted() {
    this.fetchTotalAnsweredCalls()
  }
}
</script>

<style lang="scss" scoped></style>
