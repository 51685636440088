<template>
  <StatCard
    :icon="require(`@/assets/images/icon-call_duration.svg`)"
    :loading="todayCallDurationLoading"
    :error="todayCallDurationErrorMessage"
    @retry="fetchTodayCallDuration"
  >
    <template #head>
      <div class="d-flex flex-column align-items-center">
        <p class="m-0">
          {{ $t('dashboard.statistics.service-level-details.today.title') }}
        </p>
        <h3 class="m-0">
          {{ todayDuration }}
        </h3>
      </div>
    </template>
    <template #foot>
      <h5 class="m-0 text-center text-primary">
        {{ $t('dashboard.statistics.service-level-details.today.foot-title') }}
      </h5>
      <p class="m-0 text-center text-muted">
        {{ $t('dashboard.statistics.service-level-details.today.based-on') }}
        {{ todayCallDuration.totalAnsweredCount }}
        {{
          $t('dashboard.statistics.service-level-details.today.calls-of-total')
        }}
        {{ todayCallDuration.total }}
        {{ $t('dashboard.statistics.service-level-details.today.calls') }}
      </p>
    </template>
  </StatCard>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'todayCallDuration',
      'todayCallDurationLoading',
      'todayCallDurationErrorMessage'
    ]),
    todayDuration() {
      if (!this.todayCallDuration) return ''
      const [
        hours,
        minutes,
        seconds
      ] = this.todayCallDuration.totalAnsweredDuration
      return this.$t('shared.time', { hours, minutes, seconds })
    }
  },
  methods: {
    ...mapActions(['fetchTodayCallDuration'])
  },
  mounted() {
    this.fetchTodayCallDuration()
  }
}
</script>
<style lang="scss" scoped></style>
